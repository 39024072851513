import "../PagesCss/Default.css";
import React, { useState } from "react";
import { createTheme } from "@mui/material";
import { useNavigate } from "react-router";
import ImageContainer from "./ImageContainer";
import axios from "axios"; // Import Axios
const userData = JSON.parse(localStorage.getItem("userData"));

const PesDignityPrint = () => {
  const openPDF = async (endPoint, id, fileName) => {
    setVisitedColors([...visitedColors, id]);
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/${endPoint}?EventID=${JSON.parse(localStorage.getItem("eventID")).eventID
      }&User=${JSON.parse(localStorage.getItem("userData")).username}`,
      "_blank"
    );
    return;
    // if (isLoading === false) {
    //   setIsLoading(true);
    //   setVisitedColors([...visitedColors, id]);
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(
    //         `${process.env.REACT_APP_BACKEND_URL}/api/${endPoint}/`,
    //         {
    //           ...JSON.parse(localStorage.getItem("eventID")),
    //           ...JSON.parse(localStorage.getItem("userData")),
    //         } || {},

    //         {
    //           responseType: "blob",
    //         }
    //       )
    //       .then((response) => {
    //         setIsLoading(false);
    //         const blob = new Blob([response.data], {
    //           type: "application/pdf",
    //         });
    //         const objectUrl = window.URL.createObjectURL(blob);

    //         // Trigger download with the custom filename
    //         const link = document.createElement("a");
    //         link.href = objectUrl;
    //         link.download = fileName;
    //         document.body.appendChild(link); // Required for Firefox
    //         link.click();
    //         link.remove();

    //         // Open the downloaded file in a new tab
    //         setTimeout(() => {
    //           const newTab = window.open(objectUrl, "_blank");
    //           newTab.onunload = () => {
    //             window.URL.revokeObjectURL(objectUrl);
    //           };
    //         }, 100);
    //       })
    //       .catch((error) => {
    //         setIsLoading(false);
    //         reject(error);
    //       });
    //   });
    // }
  };

  const [visitedColors, setVisitedColors] = useState([]);
  const currentYear = new Date().getFullYear();
  const defaultMaterialTheme = createTheme();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    // Function to navigate to the PesModifyRecord route
    window.scrollTo(0, 0);
  };
  const handleLogout = async (e) => {
    console.log("logout99");
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/logout/`)
      .then((response) => {
        setIsLoading(false);
        navigate("/login");
        console.log("logout", response);
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_content">
          <main>
            <div className="container-xl px-4 mt-4">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <ImageContainer selectedLanguage="en" />
                    <div className="card-body">
                      {userData.RoutingID == 101 && (
                        <>
                          <div>
                            {" "}
                            <h6 style={{marginLeft: "2.5%"}}>
                              {" "}
                              Sur la base des informations fournies les
                              documents suivants ont été pré-remplis pour vous.
                            </h6>
                          </div>
                          <hr></hr>
                          <h2
                            style={{
                              marginBottom: "7px",
                              fontWeight: "bold",
                              marginLeft: "2.5%",
                            }}
                          >
                            Impression requis
                          </h2>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("pdfdownload", 1, "");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(1) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Client accord et Fax couverture (anglais)
                                </div>
                              </div>
                              <div className="col-md-7" style={{fontSize:'14px', marginBottom: "7px",}}>
                                Print and have the family sign. Fax to PES
                                1-877-511-1917 or email cdnsubmit@progressiveestatesolutions.com
                              </div>
                            </div>
                          </div>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("ClientAgreementFR", 2, "");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(1) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Client accord et Fax couverture
                                </div>
                              </div>
                              <div className="col-md-7" style={{fontSize:'14px', marginBottom: "7px",}}>
                                Imprimez ce document et faites-le signé par la
                                famille. Envoyez-le avec une copie de
                                l’attestation de décès à PES, par télécopie
                                1-877-511-1917 ou courriel
                                cdnsubmit@progressiveestatesolutions.com
                              </div>
                            </div>
                          </div>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  onClick={() => {
                                    openPDF("StatementofServiceFR", 3, "");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(2) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Déclaration de services
                                </div>
                              </div>
                              <div className="col-md-7" style={{fontSize:'14px', marginBottom: "7px",}}>
                                Imprimez-le sur papier en-tête et le donner à la
                                famille
                              </div>
                            </div>
                          </div>
                          {/* <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  onClick={() => {
                                    openPDF("QPPfr", 4, "");
                                  }}
                                  style={{marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(2) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  French QPP Form
                                </div>
                              </div>
                              <div className="col-md-5"></div>
                            </div>
                          </div> */}
                        </>
                      )}
                      {userData.RoutingID != 101 && (
                        <>
                          <div >
                            <div className="container">  <h6>
                              {" "}
                              Based on the information provided the following
                              documents have been pre-populated for your
                              convenience.
                            </h6></div>

                            {userData.RoutingID == 104 && (
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      className="printLinks"
                                      onClick={() => {
                                        openPDF(
                                          "BCClientData",
                                          1,
                                          "BCClientData.pdf"
                                        );
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(1) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      BC Client Data Sheet
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-5"
                                    style={{
                                      marginBottom: "7px",
                                      color: "red",
                                    }}
                                  >
                                    Internal use: for locations that review
                                    data-entry
                                  </div>
                                </div>
                              </div>
                            )}
                            {userData.RoutingID == 100 && (
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      className="printLinks"
                                      onClick={() => {
                                        openPDF(
                                          "ClientData",
                                          2,
                                          "ClientData.pdf"
                                        );
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(2) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Client Data Sheet
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-5"
                                    style={{
                                      marginBottom: "7px",
                                      color: "red",
                                    }}
                                  >
                                    Internal use: for locations that review
                                    data-entry
                                  </div>
                                </div>
                              </div>
                            )}{" "}
                          </div>

                          <hr></hr>
                          <h2
                            style={{
                              marginBottom: "7px",
                              fontWeight: "bold",
                              marginLeft: "2.5%",
                            }}
                          >
                            Required Printing:
                          </h2>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "pdfdownload",
                                      3,
                                      "ClientAgreement2024.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(3) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Client Agreement & Fax Cover Sheet
                                </div>
                              </div>
                              <div className="col-md-5">
                                Print and have the family sign. Fax to PES.
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <h2
                            style={{
                              marginBottom: "7px",
                              fontWeight: "bold",
                              marginLeft: "1.7%",
                            }}
                          >
                            Optional Printing - NOTE: We provide the forms ONLY
                          </h2>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "ISPSource",
                                      4,
                                      "ISPNotification.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(4) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                    marginBottom: "7px",
                                  }}
                                >
                                  OAS and CPP Notification Form
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                To stop payment of current benefits/avoid overpayment. (formerly ISP Form).
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("CRAForm", 5, "CRA.pdf");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(5) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  GST/CRA Notification Form
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                Re-calculates Child and GST Credit entitlement.
                                Should be mailed.
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("CPPSource2024", 6, "cpp.pdf");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(6) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Death Benefit Application
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                Editable Spouse and Applicant fields.
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("CPPSurvivor", 7, "Survivor.pdf");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(7) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Survivor Benefit Application
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                Informant IS the spouse. Editable Spouse and
                                Applicant fields.
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "CPPSurvivor2a",
                                      8,
                                      "Survivor2.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(8) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Survivor Benefit Application
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                Informant IS NOT the spouse. Editable Spouse and
                                Applicant fields.
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <a
                                  className="printLinks"
                                  href="/CPP Survivor information sheet.pdf"
                                  target="_blank"
                                  onClick={() => {
                                    setVisitedColors([...visitedColors, 9]);
                                  }}
                                  style={{
                                    color:
                                      visitedColors.indexOf(9) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Survivor information sheet
                                </a>
                              </div>
                              <div
                                className="col-md-5"
                                style={{
                                  marginBottom: "7px",
                                  fontSize: "14px",
                                }}
                              >
                                Information sheet – how to apply
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("CRArep", 10, "CRArep.pdf");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(10) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Appoint CRA Rep where Deceased had No Will
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                If the Deceased had No Will you need to Assign a
                                Rep for CRA
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF("CPPStudent", 11, "CPPStudent.pdf");
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(11) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Student Benefit Application - Part 1
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    setVisitedColors([...visitedColors, 12]);
                                    return new Promise((resolve, reject) => {
                                      setIsLoading(true);
                                      axios
                                        .get(
                                          `/CPPStudent2.pdf`,

                                          {
                                            responseType: "blob",
                                          }
                                        )
                                        .then((response) => {
                                          setIsLoading(false);
                                          const blob = new Blob(
                                            [response.data],
                                            {
                                              type: "application/pdf",
                                            }
                                          );
                                          const objectUrl =
                                            window.URL.createObjectURL(blob);
                                          // link.download = "ISP-1401.pdf";
                                          const newTab = window.open(
                                            objectUrl,
                                            "_blank"
                                          );
                                          newTab.onunload = () => {
                                            window.URL.revokeObjectURL(
                                              objectUrl
                                            );
                                          };
                                        })
                                        .catch((error) => {
                                          setIsLoading(false);
                                          reject(error);
                                        });
                                    });
                                    // }
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(12) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  CPP Student Benefit Application - Part 2
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          {userData.RoutingID == 104 && (
                            <>
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF(
                                          "BCCIS",
                                          13,
                                          "BC Client Information Sheet.pdf"
                                        );
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(13) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      BC Client Information Sheet
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    Standard format
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {userData.RoutingID != 104 && (
                            <>
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF("PODSource", 14, "POD.pdf");
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(14) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Proof of Death Certificates
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-7"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Standard format
                                  </div>
                                </div>
                              </div>

                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF(
                                          "PODmargins",
                                          15,
                                          "PODmargins.pdf"
                                        );
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(15) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Proof of Death Certificates margins
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-7"
                                    style={{ fontSize: "14px" }}
                                  >
                                    For letterhead with wide margins
                                  </div>
                                </div>
                              </div>

                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF("PrivacyPOD", 16, "POD.pdf");
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(16) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Privacy POD Certificates
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-7"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Proof of Death without SIN and other details
                                  </div>
                                </div>
                              </div>

                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF("FDSSsource", 17, "POD.pdf");
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(17) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Funeral Director Statement of Services
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="container">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div
                                      onClick={() => {
                                        openPDF("PrivacyFDSS", 18, "POD.pdf");
                                      }}
                                      style={{
                                        marginBottom: "7px",
                                        color:
                                          visitedColors.indexOf(18) === -1
                                            ? "#0079F4"
                                            : "lightblue",
                                        cursor: isLoading ? "wait" : "pointer",
                                      }}
                                    >
                                      Privacy FDSS Certificates
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-7"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <div>
                                      FDSS without SIN and other details
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  onClick={() => {
                                    openPDF(
                                      "AftercareTrackingSheet",
                                      19,
                                      "POD.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(19) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Aftercare Tracking Sheet
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                <div>
                                  Can be used to track Aftercare efforts
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "CommonLawUnion",
                                      20,
                                      "CommonLawUnion.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(20) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Common Law Union Declaration
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                <div>
                                  Used in the case of a common law union for CPP
                                  and OAS Benefits
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "DeclarationLegalMarriage",
                                      21,
                                      "LegalMarriageDeclaration.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(21) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Legal Marriage Declaration
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                <div>
                                  Used when proof of Marriage required for CPP
                                  and OAS Benefits
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "StatementofServices2022",
                                      22,
                                      "SOS.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(22) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Statement of Services
                                </div>
                              </div>
                              <div
                                className="col-md-7"
                                style={{ fontSize: "14px" }}
                              >
                                <div>
                                  Print on Letterhead and give to the family
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="container">
                            <div className="row">
                              <div className="col-md-5">
                                <div
                                  className="printLinks"
                                  onClick={() => {
                                    openPDF(
                                      "ClientLetters",
                                      23,
                                      "ClientLetters.pdf"
                                    );
                                  }}
                                  style={{
                                    marginBottom: "7px",
                                    color:
                                      visitedColors.indexOf(23) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",
                                  }}
                                >
                                  Client Letters
                                </div>
                              </div>
                            </div>
                          </div>

                          {userData.RoutingID != 104 && (
                            <div className="container">
                              <div className="row">
                                <div className="col-md-5">
                                  <div
                                    className="printLinks"
                                    onClick={() => {
                                      openPDF("RSKANE", 24, "PODmargins.pdf");
                                    }}
                                    style={{
                                      marginBottom: "7px",
                                      color:
                                        visitedColors.indexOf(24) === -1
                                          ? "#0079F4"
                                          : "lightblue",
                                      cursor: isLoading ? "wait" : "pointer",
                                    }}
                                  >
                                    RS Kane Proof of Death Certificates
                                  </div>
                                </div>
                                <div
                                  className="col-md-7"
                                  style={{ fontSize: "14px" }}
                                >
                                  <div>Kane Jerrett (3152) POD</div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row">
                              <div className="col-md-5 printspace">
                                <a
                                  className="printLinks"
                                  href={`${process.env.REACT_APP_BACKEND_URL
                                    }/api/ASBForm?EventID=${JSON.parse(localStorage.getItem("eventID"))
                                      .eventID
                                    }&User=${JSON.parse(localStorage.getItem("userData"))
                                      .username
                                    }`}
                                  target="_blank"
                                  onClick={() => {
                                    openPDF(
                                      "ASBForm",
                                      25,
                                    );
                                  }}
                                  style={{
                                    color:
                                      visitedColors.indexOf(25) === -1
                                        ? "#0079F4"
                                        : "lightblue",
                                    cursor: isLoading ? "wait" : "pointer",

                                  }}
                                >
                                  AB Seniors Benefit Notification
                                </a>
                              </div>
                              <div className="col-md-7 printspacesec">
                                <div style={{ fontSize: "14px" }}>
                                  Fax to ASB to update records.
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <hr></hr>
                          <div style={{ textAlign: "center" }}><a
                            onClick={() => {
                              navigate("/FrenchandQuebecDocuments");
                            }}
                            style={{
                              marginBottom: "7px",
                              color: "#0079F4",
                            }}
                          >
                            French and Quebec Documents
                          </a></div>

                          <hr></hr>
                          {/* <div>
                            Regarding the Alberta Notification Form - Alberta
                            Seniors Benefit and Alberta Health Care are
                            automatically cancelled if the death occurred in
                            Alberta. For OAS/CPP cancellation please use the
                            form above.
                          </div> */}
                          {/* <hr></hr> */}
                        </>
                      )}{" "}


                      {userData.RoutingID == 101 && (
                        <>
                        <hr/>
                          <div className="three_button_class">
                            <a
                              class="stylish-button"
                              style={{ marginBottom: "7px", textAlign: "center" }}
                              href="/dignityModify"
                            >
                              Modifier l'entrée
                            </a>
                            <a
                              class="stylish-button"
                              style={{ marginBottom: "7px", textAlign: "center" }}
                              href="/dignityInput"
                              onClick={handleNavigate}
                            >
                              Nouvelle entrée
                            </a>
                            <button
                              style={{ marginBottom: "7px", width: "200px" }}
                              class="stylish-buttonSignout"
                              onClick={handleLogout}
                            >
                              Se déconnecter
                            </button>
                          </div>
                        </>
                      )}

                      {userData.RoutingID != 101 && (
                        <>
                          <div className="three_button_class">
                            <a
                              class="stylish-button"
                              style={{ marginBottom: "7px", textAlign: "center" }}
                              href="/dignityModify"
                            >
                              MODIFY ENTRY
                            </a>
                            <a
                              class="stylish-button"
                              style={{ marginBottom: "7px", textAlign: "center" }}
                              href="/dignityInput"
                              onClick={handleNavigate}
                            >
                              NEW ENTRY
                            </a>
                            <button
                              style={{ marginBottom: "7px", width: "200px" }}
                              class="stylish-buttonSignout"
                              onClick={handleLogout}
                            >
                              Sign Out
                            </button>
                          </div>
                        </>
                      )

                      }
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-5 small">
                  Copyright © Progressive Estate Solutions Inc. {currentYear}
                </div>
                <div className="col-md-5 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default PesDignityPrint;
